const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';


/**
 * Play video on click
 */

$('.play-video').on('click', function () {

	const videoWrapper = $(this).parents('.video');
	const video = videoWrapper.find('video');

	videoWrapper.addClass('video-playing');
	video.attr('controls', 'controls')
	video[0].play();
	$('.play-video').fadeOut();
});


/**
 * Toggle accordions
 */

$('.accordion__toggle').on('click', function (e) {
	$(this).parent().toggleClass('active');

	let content = $(this).next();

	content.slideToggle();
});


/**
 * Isotope filters
 */

let $grid;

if ($('.tax-categoria_prodotto .products-grid').length > 0)
{
	$grid = $('.products-grid', '.tax-categoria_prodotto').isotope({
		initLayout:      false,
		itemSelector:    'article',
		percentPosition: true,
		layoutMode:      'fitRows',
		fitRows:         {
			gutter: 20
		}
	});

	// call equalHeights script on arrangeComplete
	$grid.on('layoutComplete arrangeComplete', function (event, filteredItems) {
		setEqualHeightsPerRow('.tax-categoria_prodotto .products-grid', 'article');
	});

	$grid.isotope();

	_window.on('load', function (event) {
		$grid.isotope('layout')
	});

	// find isotope rows and run equal heights
	function setEqualHeightsPerRow(container, items) {

		let row = 1;
		let top = 0;

		$(container).find(items).each(function () {

			$(this).removeClass(function (index, className) {
				return (className.match(/(^|\s)row-\S+/g) || []).join(' ');
			});

			let itemTop = parseInt($(this).css('top'), 10);

			if ($(this).is(':visible'))
			{
				if (itemTop === top)
				{
					$(this).addClass('row-' + row);
				}
				else
				{
					top = itemTop;
					row++;
					$(this).addClass('row-' + row);
				}
			}
		});

		for (let i = 1; i <= row; i++)
		{
			setEqualHeights('.tax-categoria_prodotto .products-grid', 'article.row-' + i);
		}
	}


	function setEqualHeights(container, item) {

		$(container).each(function () {

			let selectedContainer = $(this);
			let height = 0;

			// Reset all the heights so the reading is correct
			selectedContainer.find(item).css('min-height', height);

			// Figure out which item is the tallest
			selectedContainer.find(item).each(function () {
				let item = $(this);
				let itemHeight = item.outerHeight();
				if (itemHeight > height)
				{
					height = itemHeight;
				}
			});

			// Set min height on all the items
			selectedContainer.find(item).css('min-height', height);
		});

	}

}


/**
 * Isotope filters
 */

// store filter for each group
let filters = {};

// seleziona il valore del filtro, filtra i prodotti e chiude il filtro
$('span', '.filter-values-list').on('click, click.noScroll', function (e) {
	let item = $(this);
	let filterValuesList = item.parents('.filter-values-list');

	// select clicked filter item, deselect others
	filterValuesList.find('.selected').removeClass('selected');
	item.addClass('selected');

	// get group key
	let filterGroup = filterValuesList.attr('data-filter-group');

	// set filter for group
	filters[filterGroup] = item.attr('data-value');

	// combine filters
	let filterValue = concatValues(filters);

	// filter items
	$grid.isotope({filter: filterValue});
});

// flatten object by concatenating values
function concatValues(obj) {
	let value = '';
	for (let prop in obj)
	{
		value += obj[prop];
	}
	return value;
}



// activate selected item
$('.title', '.tabs__header').off('click').on('click', function () {
	const item = $(this).attr('data-item');

	$(this).addClass('active').siblings().removeClass('active');

	const activeItem = $('div[data-item="' + item + '"]', '.tabs__tabs');
	activeItem.addClass('active').siblings().removeClass('active');

	if ($('.slideshow', activeItem).length > 0)
	{
		let slideshow = $('.slideshow', activeItem).data('flickity');
		slideshow.resize();
	}

	$('.tabs__tabs').height(activeItem.outerHeight());

	goToByScroll($('.tabs'), -40);
});



if ($('.store-locator').length > 0)
{
	// init Isotope
	let $grid = $('.grid').isotope({
		itemSelector: '.element-item',
		layoutMode:   'fitRows'
	});

	// bind filter on select change
	$('.region-select').on('change', function () {

		// get filter value from option value
		let filterValue = this.value;

		// use filterFn if matches value
		$grid.isotope({filter: filterValue});
	});

}

if (document.body.classList.contains('single-post'))
{
	let p_style = document.querySelectorAll('.entry-content p img')

	p_style.forEach(item => {
		item.parentNode.classList.add('bold-text-image');
	})
}


/**
 * ON LOAD
 */

_window.on('load', function (event) {

});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/