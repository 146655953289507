/**
 *  Merge columns contents on mobile and restore columns on tablet and above
 */
enquire.register("screen and (max-width: 767px)", {
	match : function() {
		$('.merge-cols-on-sm').each(function () {
			let row = $(this);

			// store column layout
			let rowContents = row.html();
			row.data('rowContents', rowContents);

			// get columns content and unwrap columns
			row.children().each(function () {
				$(this).contents().unwrap();
			});

			// wrap contents in a single full-width column
			row.contents().wrapAll('<div class="col-12 d-flex flex-column"></div>')
		});
	},
	unmatch : function() {
		$('.merge-cols-on-sm').each(function () {
			let row = $(this);

			// retrieve previous layout
			let rowContents = row.data('rowContents');

			// empty the row and restore the previous layout
			row.empty().append(rowContents);
		});
	}
});


/*enquire.register("screen and (max-width: 1439.98px)", {
	match : function() {
		// move #site-navigation inside #mega-menu
		$('#site-navigation').detach().prependTo('#header-widgets-1');
	},
	unmatch : function() {
		// move #navigation inside #header
		$('#site-navigation').detach().appendTo('#header .row .col:nth-child(2)');
	}
});*/