const _window = $(window);
const body = $('body');
let scrollPos = 0;

function checkScroll() {
	// _window.scrollTop() is equivalent to (document.body.getBoundingClientRect()).top with inverse sign
	let scrollDistance = 400;

	if (scrollPos !== 0)
	{
		// detects new scroll state and compares it with the old one
		if (_window.scrollTop() < scrollPos)
		{
			if ((_window.scrollTop() - scrollPos) < -20)
			{
				body.addClass('scrolled-up').removeClass('scrolled-down');
			}
		}
		else
		{
			body.addClass('scrolled-down').removeClass('scrolled-up');
		}
	}

	// saves the new position for iteration
	scrollPos = _window.scrollTop();

	if (scrollPos === 0)
	{
		body.removeClass('scrolled-up scrolled-down');
	}

	if (_window.scrollTop() > scrollDistance)
	{
		if (!body.hasClass('sticky-header'))
		{
			body.addClass('to-sticky-header');
		}

		body.addClass('sticky-header');

		if (body.hasClass('to-sticky-header'))
		{
			setTimeout(function () {
				body.removeClass('to-sticky-header');
			}, 50);
		}
	}
	else
	{
		if (body.hasClass('sticky-header'))
		{
			body.addClass('to-normal-header');
		}

		if (body.hasClass('to-normal-header'))
		{
			setTimeout(function () {
				body.removeClass('to-normal-header');
				body.removeClass('sticky-header');
			}, 50);
		}
	}
}

checkScroll();


window.addEventListener('scroll', function() {

	checkScroll();

});