let slideshowSwiper = new Swiper('.slideshow .swiper', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

let heroHomeSwiper = new Swiper('.hero-home .swiper', {
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 900,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});


let articlesSwiper = new Swiper('.articles-slider .swiper', {
	slidesPerView: 'auto',
	navigation: {
		nextEl: '.articles-slider .swiper-button-next',
		prevEl: '.articles-slider .swiper-button-prev',
	},
});