/**
 * Scroll smoothly the page towards the given element
 * @param elem
 * @param offset
 * @param speed
 */
export function goToByScroll(elem, offset, speed) {
	if (typeof offset === 'undefined')
	{
		offset = 0;
	}
	if (typeof speed === 'undefined')
	{
		speed = 'slow';
	}

	// close mobile menu
	$('body').removeClass('menu-open');
	$('#menu-toggle').removeClass('tcon-transform');

	$('html,body').animate({scrollTop: elem.offset().top + offset}, speed);
}